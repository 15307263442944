import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDDPQuery } from '@zedoc/ddp-connector';
import { toIntlDateTimeFormat } from '@zedoc/date';
import Box from '../../common/components/primitives/Box';
import Text from '../../common/components/base/Text';
import List from '../../common/components/List';
import { default as ParticipationSelect } from '../../common/selectors/Participation';
import Modal from './Modal';
import { getUserNames } from '../../common/api/aggregations/Projects';

const DischargeComments =
  // ...
  ({ visible, projectId, participationId, onClose }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();

    const { value: userNames } = useDDPQuery(
      projectId &&
        getUserNames.withParams({
          projectId,
        }),
    );

    const participation = useSelector(
      ParticipationSelect.one().whereIdEquals(participationId),
    );

    return (
      <Modal title={t('notes')} visible={visible} onCancel={onClose} hideFooter>
        {!isEmpty(participation?.getComments()) ? (
          <Box.Primary>
            <List
              dataSource={participation.getComments()}
              renderItem={(comment, idx) => (
                <div key={idx}>
                  <Text.Paragraph importance="high">
                    {userNames && userNames[comment.userId]}{' '}
                    {toIntlDateTimeFormat(language, comment.timestamp, {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    })}
                  </Text.Paragraph>
                  <Text.Paragraph>
                    {comment.contentKey
                      ? t(comment.contentKey)
                      : comment.content}
                  </Text.Paragraph>
                </div>
              )}
            />
          </Box.Primary>
        ) : (
          t('forms:note.none')
        )}
      </Modal>
    );
  };

DischargeComments.propTypes = {
  visible: PropTypes.bool,
  projectId: PropTypes.string,
  participationId: PropTypes.string,
  onClose: PropTypes.func,
};

DischargeComments.defaultProps = {
  visible: false,
  projectId: null,
  participationId: null,
  onClose: () => {},
};

export default DischargeComments;
