import React, { useMemo, useCallback } from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toIntlDateTimeFormat } from '@zedoc/date';
import Table from '../../../components/lists/Table';
import { apiAdminSearchUsersGroups } from '../../../common/api/admin';
import Highlighter from '../../../common/components/Highlighter';
import { default as UsersGroupSelect } from '../../../common/selectors/UsersGroup';
import usePagination from '../../../utils/usePagination';
import useHighlight from '../../../utils/useHighlight';
import {
  getFilters,
  getSorter,
  setGroup,
  setUsersGroupDialogVisible,
} from '../store';

const UsersGroupsTable = React.memo(() => {
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    ready: subscriptionsReady,
    items: usergroups,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: UsersGroupSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiAdminSearchUsersGroups.withParams({
        filters: map(filters, (filter) => omit(filter, 'meta')),
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });

  const highlight = useHighlight(filters, ['name', 'belongsTo', 'type']);

  const handleSelectUsersGroup = useCallback(
    (group) => {
      const groupEditable = {
        ...group,
        roles: (group.roles && group.roles.map((role) => role.id)) || [],
      };
      dispatch(setGroup(groupEditable));
      dispatch(setUsersGroupDialogVisible(true));
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        dataIndex: 'name',
        render: (_, userGroup) => (
          <Highlighter text={userGroup.getName()} highlight={highlight} />
        ),
      },
      {
        title: t('forms:belongsTo.label'),
        dataIndex: 'belongsTo',
        render: (_, userGroup) => (
          <Highlighter text={userGroup.belongsTo} highlight={highlight} />
        ),
      },
      {
        title: t('type'),
        dataIndex: 'type',
        render: (_, userGroup) => (
          <Highlighter text={userGroup.type} highlight={highlight} />
        ),
      },
      {
        title: t('updatedAt'),
        dataIndex: 'UpdatedAt',
        render: (_, userGroup) =>
          toIntlDateTimeFormat(language, userGroup.updatedAt, {
            dateStyle: 'medium',
            timeStyle: 'short',
          }),
      },
    ],
    [t, highlight, language],
  );

  return (
    <Table
      rowKey="_id"
      dataSource={usergroups}
      columns={columns}
      loading={!subscriptionsReady}
      onRow={(userGroup) => ({
        onClick: () => handleSelectUsersGroup(userGroup),
      })}
      pagination={getPaginationProps()}
    />
  );
});

export default UsersGroupsTable;
