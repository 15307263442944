import moment from 'moment';
import isNaN from 'lodash/isNaN';
import { zoneToUtcOffset } from './zone';
import { YEAR_MONTH_DAY, DATE_TIME_FORMAT } from './constants';

/**
 * @param {string} locale
 * @param {Date} date
 * @param {object} [options]
 * @return {string}
 */
export function toIntlDateTimeFormat(locale, date, options) {
  if (moment(date).isValid()) {
    return Intl.DateTimeFormat(
      // NOTE: Use "-GB" to force dd/mm/yyyy date styling
      locale === 'en' ? 'en-GB' : locale,
      options,
    ).format(moment(date).toDate());
  }

  return '';
}

/**
 * @param {string | Date} date
 * @param {number} [utcOffset]
 * @return {string}
 */
export function toYearMonthDay(date, utcOffset = 0) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format(YEAR_MONTH_DAY);
}

/**
 * @param {string} yearMonthDay
 * @param {number} days
 * @return {string}
 */
export function yearMonthDayShift(yearMonthDay, days) {
  if (typeof yearMonthDay !== 'string' || yearMonthDay.length < 10) {
    return yearMonthDay;
  }
  const ts = new Date(`${yearMonthDay}T00:00:00Z`).getTime();
  if (isNaN(ts)) {
    return yearMonthDay;
  }
  return new Date(ts + days * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
}

/**
 * @param {number} [utcOffset]
 * @return {string}
 */
export function getCurrentYearMonthDay(utcOffset = 0) {
  return toYearMonthDay(new Date(), utcOffset);
}

/**
 * @param {string | Date} date
 * @param {number} [utcOffset]
 * @return {string}
 */
export function toHoursMinutes(date, utcOffset = 0) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format('HH:mm');
}

/**
 * @param {string} date
 * @param {number} [utcOffset]
 * @return {string}
 */
export function toHoursMinutesSeconds(date, utcOffset = 0) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format('HH:mm:ss');
}

/**
 * @param {number} [utcOffset]
 * @return {string}
 */
export function getCurrentHoursMinutes(utcOffset = 0) {
  return toHoursMinutes(new Date(), utcOffset);
}

/**
 * @param {string | Date} date
 * @param {number} [utcOffset]
 * @return {string}
 */
export function toDateTimeString(date, utcOffset = 0) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format(DATE_TIME_FORMAT);
}

/**
 * @param {number} [utcOffset]
 * @return {string}
 */
export function getCurrentDateTimeString(utcOffset = 0) {
  return toDateTimeString(new Date(), utcOffset);
}

/**
 * Checks if the given date string contains utcOffset information.
 * @param {string} isoString
 * @returns {boolean}
 */
export function hasUtcOffset(isoString) {
  return (
    /Z$/.test(isoString) ||
    /\+\d\d:\d\d$/.test(isoString) ||
    /\+\d\d\d\d$/.test(isoString) ||
    /-\d\d:\d\d$/.test(isoString) ||
    /-\d\d\d\d$/.test(isoString)
  );
}
/**
 * @param {string} isoString
 * @param {number} nDays
 * @param {string } [timezone]
 * @returns {string | null}
 */
export function addDays(isoString, nDays, timezone) {
  /** @type {moment.Moment} */
  let m;
  if (!hasUtcOffset(isoString)) {
    // NOTE: In this case it is easy, because timezone does not change the result. We can just assume timezone = UTC and we will be fine.
    m = moment.utc(isoString, moment.ISO_8601).add(nDays, 'days');
  } else if (!timezone) {
    // NOTE: If timezone is not specified we try to re-use the same utc offset.
    m = moment.parseZone(isoString).add(nDays, 'days');
  } else {
    const ts = moment
      .utc(isoString, moment.ISO_8601)
      .add(nDays, 'days')
      .toDate();
    const utcOffset = zoneToUtcOffset(timezone)(ts);
    m = moment(ts).utcOffset(utcOffset);
  }
  if (m.isValid()) {
    return m.format(YEAR_MONTH_DAY);
  }
  return null;
}

/**
 * Given date of birth and date now compute age.
 * Don't use moment.js as it gives inaccurate results;
 * instead derive the value from the difference in years.
 * @param {string} dateOfBirth
 * @param {string} dateNow
 * @returns {number}
 */
export function computeAge(dateOfBirth, dateNow) {
  const dob = new Date(dateOfBirth);
  const now = new Date(dateNow);
  const age = now.getFullYear() - dob.getFullYear();
  const m = now.getMonth() - dob.getMonth();
  if (m < 0 || (m === 0 && now.getDate() < dob.getDate())) {
    return age - 1;
  }
  return age;
}

/**
 * @param {string} time
 * @returns {Date | string}
 */
export function toDateFromHoursMinutesSeconds(time) {
  const [hours, minutes, seconds] = time.split(':');

  if (!hours || !minutes || !seconds) {
    return time;
  }

  const dt = new Date();
  dt.setHours(Number(hours));
  dt.setMinutes(Number(minutes));
  dt.setSeconds(Number(seconds));
  return dt;
}
