import BaseModel from './BaseModel';

class SignedNote extends BaseModel {
  renderName(userNames) {
    const name = userNames[this.createdBy] || '[unknown]';
    return name;
  }

  renderContent() {
    return this.content || '';
  }
}

export default SignedNote;
