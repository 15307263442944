import styled from 'styled-components';
import { useInputNumber } from '@zedoc/react-hooks';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback } from 'react';
import { Remove, Add } from 'styled-icons/material';
import Input from '../Input';

const Component = styled(Input)`
  /* TODO: I encountered a specificity issue with InputWithSuffix */
  && {
    padding-right: 0;
    padding-left: 0;
  }

  input {
    text-align: center;
    width: 4rem;

    /* Hide input number up/down arrows */
    -moz-appearance: textfield !important;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }
`;

const InputNumber = forwardRef(
  (
    {
      'data-testid': datatestid,
      id,
      value,
      onChange,
      onBlur,
      size,
      min,
      max,
      step,
      precision,
      placeholder,
      disabled,
      autoFocus,
      disableState,
    },
    ref,
  ) => {
    const {
      state,
      handleOnDecrease,
      handleOnIncrease,
      handleOnChange,
      handleOnBlur: handleOnBlurInputNumber,
    } = useInputNumber({
      value,
      onChange,
      precision,
      max,
      min,
      step,
      disableState,
    });

    const handleOnBlur = useCallback(
      (event) => {
        handleOnBlurInputNumber(event);
        if (onBlur) {
          onBlur();
        }
      },
      [handleOnBlurInputNumber, onBlur],
    );

    return (
      <Component
        ref={ref}
        data-testid={datatestid}
        id={id}
        type="number"
        inputMode="decimal"
        size={size}
        value={state}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        prefix={
          <button
            type="button"
            onClick={handleOnDecrease}
            className="px-4 hover:bg-surface-overlay transition-colors disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:text-disabled"
            disabled={disabled}
          >
            <Remove className="w-4 h-4" />
          </button>
        }
        suffix={
          <button
            type="button"
            onClick={handleOnIncrease}
            className="px-4 hover:bg-surface-overlay transition-colors disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:text-disabled"
            disabled={disabled}
          >
            <Add className="w-4 h-4" />
          </button>
        }
      />
    );
  },
);

InputNumber.propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  precision: PropTypes.number,
};

InputNumber.defaultProps = {
  'data-testid': null,
  id: null,
  value: null,
  onChange: () => {},
  onBlur: () => {},
  size: 'default',
  disabled: false,
  autoFocus: false,
  placeholder: null,
  min: null,
  max: null,
  step: 1,
  precision: 0,
};

export default InputNumber;
