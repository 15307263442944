import React, { useMemo } from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toIntlDateTimeFormat } from '@zedoc/date';
import Table from '../../../components/lists/Table';
import { apiAdminSearchRoles } from '../../../common/api/admin';
import Highlighter from '../../../common/components/Highlighter';
import { default as RoleSelect } from '../../../common/selectors/Role';
import usePagination from '../../../utils/usePagination';
import useHighlight from '../../../utils/useHighlight';
import {
  getFilters,
  getSorter,
  setEditUserDialogVisible,
  setIsEditing,
  setSelectedRole,
} from '../store';

const RolesTable = React.memo(() => {
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    ready: subscriptionsReady,
    items: roles,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: RoleSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiAdminSearchRoles.withParams({
        filters: map(filters, (filter) => omit(filter, 'meta')),
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });

  const highlight = useHighlight(filters, ['name', 'belongsTo']);

  const dispatch = useDispatch();
  const handleSelectRole = (role) => {
    dispatch(setSelectedRole(role));
    dispatch(setEditUserDialogVisible(true));
    dispatch(setIsEditing(true));
  };

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        dataIndex: 'name',
        render: (_, role) => (
          <Highlighter text={role.getName()} highlight={highlight} />
        ),
      },
      {
        title: t('forms:belongsTo.label'),
        dataIndex: 'belongsTo',
        render: (_, role) => (
          <Highlighter text={role.getDomain()} highlight={highlight} />
        ),
      },
      {
        title: t('forms:date.label'),
        dataIndex: 'createdAt',
        render: (_, role) =>
          toIntlDateTimeFormat(language, role.createdAt, {
            dateStyle: 'medium',
            timeStyle: 'short',
          }),
      },
    ],
    [t, highlight, language],
  );

  return (
    <Table
      rowKey="_id"
      dataSource={roles}
      columns={columns}
      loading={!subscriptionsReady}
      onRow={(role) => ({
        onClick: () => handleSelectRole(role),
      })}
      pagination={getPaginationProps()}
    />
  );
});

export default RolesTable;
