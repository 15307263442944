import moment from 'moment';
import forEach from 'lodash/forEach';
import flatMap from 'lodash/flatMap';
import some from 'lodash/some';
import map from 'lodash/map';
import { isPrefix } from '@zedoc/text';
import BaseModel from './BaseModel';
import PermissionsDomain from './PermissionsDomain';
import { VISIBILITY_LIMITED } from '../constants';

class Role extends BaseModel {
  constructor(doc) {
    super(doc);
    this.permissions = this.permissions || [];

    const permissionsByKey = {};
    this.permissions.forEach((p) => {
      // NOTE: Permission tier can overwrite global role tier
      permissionsByKey[p.key] = {
        tier: p.tier || this.tier || 0,
      };
      if (p.scope) {
        permissionsByKey[p.key].scope = p.scope;
      }
    });
    Object.defineProperty(this, 'permissionsByKey', {
      value: permissionsByKey,
    });
  }

  mergePermissionsInto(permissionsByKey, scope) {
    forEach(this.permissionsByKey, (permission, key) => {
      if (permission.scope) {
        // Scoped permission can only be applied in scoped queries.
        if (!isPrefix(permission.scope)(scope)) {
          return;
        }
      }
      // eslint-disable-next-line no-param-reassign
      permissionsByKey[key] = Math.max(
        permission.tier,
        permissionsByKey[key] || 0,
      );
    });
    return permissionsByKey;
  }

  hasPermission(key, { tier } = {}) {
    if (!this.permissionsByKey[key]) {
      return false;
    }
    if (tier === undefined || tier === null) {
      return true;
    }
    if (typeof tier === 'number') {
      return this.permissionsByKey[key].tier >= tier;
    }
    return false;
  }

  getDomain() {
    return this.belongsTo;
  }

  getName() {
    return this.name;
  }

  getDescription() {
    const parts = this.belongsTo.split('/');
    return `${this.name} (${parts[parts.length - 2]})`;
  }

  /**
   * @param {string} domain
   */
  isApplicableTo(domain) {
    return PermissionsDomain.contains(this.belongsTo, domain);
  }

  /**
   * @param {Array<String>} realm
   */
  isVisibleTo(realm) {
    if (this.visibility === VISIBILITY_LIMITED) {
      return some(
        realm,
        (domain) =>
          PermissionsDomain.contains(this.belongsTo, domain) ||
          PermissionsDomain.contains(domain, this.belongsTo),
      );
    }

    return PermissionsDomain.belongsToRealm([this.belongsTo], realm);
  }

  getReference() {
    return {
      id: this._id,
      tier: this.tier,
      name: this.name,
      tagsNames: this.tags ? this.tags.map((x) => x.name) : [],
      appliesTo: this.getDomain(),
    };
  }

  static roleHasPermission({ roleId, tier, permission, rolesDB = {} }) {
    const role = rolesDB[roleId];
    return (
      !!role &&
      role.hasPermission(permission, {
        tier,
      })
    );
  }

  static extractFlatPermissions({
    rolesIds = [],
    groupsIds = [],
    rolesDB = {},
    groupsDB = {},
    scope,
  }) {
    const allRoleIds = [
      ...rolesIds,
      ...flatMap(groupsIds, (id) => {
        const group = groupsDB[id] || { roles: [] };
        return map(group.roles, 'id');
      }),
    ];

    const flatCopy = {};
    forEach(allRoleIds, (id) => {
      const role = rolesDB[id];
      if (role) {
        role.mergePermissionsInto(flatCopy, scope);
      }
    });

    return flatCopy;
  }
}

Role.collection = 'Roles';

export default Role;
