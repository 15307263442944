import moment from 'moment';
import BaseModel from './BaseModel';

class UsersGroup extends BaseModel {
  getName() {
    return this.name;
  }

  getDomain() {
    return this.belongsTo;
  }

  getReference() {
    const { _id: id, name, type } = this;
    return {
      id,
      name,
      type,
      appliesTo: this.getDomain(),
    };
  }
}

UsersGroup.collection = 'UsersGroups';
export default UsersGroup;
