import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toIntlDateTimeFormat } from '@zedoc/date';
import Recipient from '../../../../../common/models/Recipient';
import Tooltip from '../../../../../common/components/Tooltip';
import useElementTruncated from '../../../../../utils/useElementTruncated';

const PatientColumn = ({ recipient, projectId }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { ref, isElementTruncated } = useElementTruncated();

  if (!recipient) {
    return null;
  }

  const name = recipient.getFullName();
  return (
    <>
      <Tooltip title={isElementTruncated ? name : ''}>
        <Link
          ref={ref}
          to={`/patients/${recipient._id}/projects/${projectId}`}
          // NOTE: "align-top" is used because "inline-block" creates extra blank spacing below the element
          className="truncate inline-block max-w-full align-top text-primary-800 hover:underline"
        >
          {name}
        </Link>
      </Tooltip>
      <p>
        {recipient.getNationalId() && (
          <span>
            {recipient.getNationalId()}
            {' · '}
          </span>
        )}
        <span>
          {toIntlDateTimeFormat(language, recipient.birthDate, {
            dateStyle: 'short',
          })}
        </span>
      </p>
    </>
  );
};

PatientColumn.propTypes = {
  recipient: PropTypes.instanceOf(Recipient).isRequired,
  projectId: PropTypes.string.isRequired,
};

export default PatientColumn;
