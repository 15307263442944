import {
  createQuery,
  createSubscription,
  updateQuery,
  updateSubscription,
} from '@zedoc/ddp-connector/lib/actions';

const CREATE_ACTION_TYPES = [createQuery, createSubscription].map((x) =>
  x.toString(),
);
const UPDATE_ACTION_TYPES = [updateQuery, updateSubscription].map((x) =>
  x.toString(),
);

const DDP_EVENT_TYPE = 'com.zedoc.rum.ddp_event';

const MONITORED_EVENTS = ['api.zedoc.searchRecipients'];

// Create an empty object to store action details
const actionMap = {};

/**
 * Redux middleware function for real user monitoring.
 */
export const realUserMonitoringMiddleware =
  (rumClient) => (_) => (next) => (action) => {
    try {
      // Check if the action type is in the create action types and the request name is in the included events
      if (
        CREATE_ACTION_TYPES.includes(action.type) &&
        MONITORED_EVENTS.includes(action.payload?.request?.name)
      ) {
        // Store the action details in the action map
        actionMap[action.payload.id] = {
          pageIndex: action.payload.request.params?.[0]?.pageIndex,
          requestName: action.payload.request.name,
          startTime: new Date().getTime(),
        };
      }
    } catch {
      // Fail silently so that the middleware does not break the application
      // TODO: Log error?
    }

    // Call the next middleware with the action
    next(action);

    try {
      // Check if the action type is in the update action types and the payload is ready or has an error
      if (
        UPDATE_ACTION_TYPES.includes(action.type) &&
        (action.payload?.ready || action.payload?.error)
      ) {
        // Get the action details from the action map
        const actionDetails = actionMap[action.payload.id];
        if (actionDetails) {
          // Calculate the request duration
          const requestDuration =
            new Date().getTime() - actionDetails.startTime;
          // Record the event using the rumClient
          rumClient.recordEvent(DDP_EVENT_TYPE, {
            duration: requestDuration,
            name: actionDetails.requestName,
            pageIndex: actionDetails.pageIndex,
          });
          // Remove the action details from the action map
          delete actionMap[action.payload.id];
        }
      }
    } catch {
      // Fail silently so that the middleware does not break the application
      // TODO: Log error?
    }
  };
