import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { toIntlDateTimeFormat } from '@zedoc/date';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Stack from '../../../../common/components/primitives/Stack';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Box from '../../../../common/components/primitives/Box';
import Cluster from '../../../../common/components/primitives/Cluster';
import Text from '../../../../common/components/base/Text';
import Empty from '../../../../common/components/base/Empty';
import EditNote from './EditNote';

const PatientNotes = compose(
  connect(
    () => ({}),
    (dispatch) => ({
      doCreate: () =>
        dispatch(submit('screens_patients_directory__addNewNote')),
      doSubmit: (form) => dispatch(submit(form)),
    }),
  ),
)(
  ({
    onAddNote,
    onCreate,
    onUpdate,
    onCancel,
    onEdit,
    onRemove,
    notes,
    userNames,
    activeNoteId,
    recipientId,
    editing,
    doCreate,
    doSubmit,
  }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();

    return (
      <Stack>
        <Sidebar
          sidebar={
            <Button
              data-testid="note-button-add-note"
              type="primary"
              icon={<PlusOutlined />}
              onClick={onAddNote}
            >
              {t('addNote')}
            </Button>
          }
        />
        {editing && !activeNoteId && (
          <Box.Primary>
            <Sidebar
              sidebar={
                <Cluster>
                  <Button
                    data-testid="note-button-add"
                    icon={<EditOutlined />}
                    onClick={doCreate}
                  >
                    {t('add')}
                  </Button>
                  <Button
                    data-testid="note-button-cancel"
                    type="ghost"
                    onClick={onCancel}
                    hasBorder={false}
                    ghost
                  >
                    {t('cancel')}
                  </Button>
                </Cluster>
              }
            >
              <EditNote
                form="screens_patients_directory__addNewNote"
                recipientId={recipientId}
                onCreate={onCreate}
                editing
              />
            </Sidebar>
          </Box.Primary>
        )}
        {notes.map(({ id, createdBy, createdAt, content }) => (
          <Box.Primary key={id}>
            <Sidebar
              sidebar={
                activeNoteId !== id ? (
                  <Cluster>
                    <Button
                      data-testid="note-button-edit"
                      type="ghost"
                      icon={<EditOutlined />}
                      onClick={() => onEdit(id)}
                    >
                      {t('edit')}
                    </Button>
                    <Button
                      data-testid="note-button-remove"
                      type="danger"
                      onClick={() =>
                        onRemove({
                          id,
                          recipientId,
                        })
                      }
                      hasBorder={false}
                      ghost
                    >
                      {t('remove')}
                    </Button>
                  </Cluster>
                ) : (
                  <Cluster>
                    <Button
                      data-testid="note-button-save"
                      icon={<EditOutlined />}
                      onClick={() =>
                        doSubmit(`screens_patients_directory__editNote_${id}`)
                      }
                    >
                      {t('save')}
                    </Button>
                    <Button
                      data-testid="note-button-cancel"
                      type="ghost"
                      onClick={onCancel}
                      hasBorder={false}
                      ghost
                    >
                      {t('cancel')}
                    </Button>
                  </Cluster>
                )
              }
            >
              <Stack space={0}>
                <EditNote
                  form={`screens_patients_directory__editNote_${id}`}
                  id={id}
                  content={content}
                  editing={activeNoteId === id}
                  recipientId={recipientId}
                  onUpdate={onUpdate}
                  onCancel={onCancel}
                />
                <Text.Paragraph size="small" importance="low">
                  {(userNames && userNames[createdBy]) || `[${t('unknown')}]`}
                  {` ${t('on')} `}
                  {toIntlDateTimeFormat(language, createdAt, {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                </Text.Paragraph>
              </Stack>
            </Sidebar>
          </Box.Primary>
        ))}
        {isEmpty(notes) && <Empty />}
      </Stack>
    );
  },
);

PatientNotes.propTypes = {
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  activeNoteId: PropTypes.string,
  recipientId: PropTypes.string,
  editing: PropTypes.bool,
  userNames: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string,
      createdBy: PropTypes.string,
      createdAt: PropTypes.instanceOf(Date),
    }),
  ),
};

const noop = () => {};
PatientNotes.defaultProps = {
  onCreate: noop,
  onUpdate: noop,
  onCancel: noop,
  onEdit: noop,
  onRemove: noop,
  activeNoteId: null,
  recipientId: null,
  editing: false,
  userNames: {},
  notes: [],
};

export default PatientNotes;
